import {ajaxGet, ajaxPost} from "@/utilities";
import {EventBus} from "@/event-bus";


const actions = {
    getTaskUser({rootState}, userId) {
        return ajaxGet(rootState.baseUrl + '/admin/taskmanager/task/user?user_id=' + userId).then((resp) => {
            return resp;
        })
    },
    getUserList: ({rootState}, data) => {
        return ajaxPost(rootState.baseUrl + `/admin/clients/search?perPage=10&page=${data.page}`, data, true).then((data) => data)
    },
    changeTaskStatus({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/taskmanager/task/status', payload).then((data) => {
            // EventBus.$emit('updateListUsersCRM')
            return data;
        })
    },
    getDoctors({rootState, commit}) {
        return ajaxGet(rootState.baseUrl + "/admin/doctors/all", true).then((data) => {
            commit('SET_ALL_DOCTORS', data);
            return (data);
        })
    },
    assignDoctor({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/taskmanager/assign/user/doctor', payload).then((reap) => reap)
    },
    getListSalesStuff({rootState}, page) {
        return ajaxGet(rootState.baseUrl + "/admin/taskmanager/sell/unassigned/user/list?page=" + page).then((resp) => resp)
    },
    selectSalesStuff({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/admin/taskmanager/sell/unassigned/user/select ", payload).then((resp) => {
            return resp
        })
    },
    // doneTask({rootState}, payload) {
    //     return ajaxPost(rootState.baseUrl + '/admin/taskmanager/task/status/done', payload).then((data) => {
    //         // if (payload.needReloadUsersList){
    //         //     EventBus.$emit('updateListUsersCRM')
    //         // }
    //         EventBus.$emit('updateListUsersCRM')
    //         return data;
    //     })
    // },
    getAccountMangerList({rootState}) {
        return ajaxGet(rootState.baseUrl + "/admin/taskmanager/admin/accountmanager/list").then((data) => {
            return data;
        })
    },
    assignAccountManger({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/taskmanager/assign/user/accountmanager', payload).then((data) => {
            EventBus.$emit('updateListUsersCRM')
            return data;
        })
    },
    getNotesClient({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/usercrmnote/index/${payload.clientId}?page=${payload.page}`).then(data => data)
    },
    addNoteClient({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/usercrmnote/create', payload).then(resp => resp)
    },
    editNoteClient({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/usercrmnote/update/${payload.note_id}`, payload).then(resp => resp)
    },
    deleteNote({rootState}, userCrmNote) {
        return ajaxGet(rootState.baseUrl + `/admin/usercrmnote/delete/${userCrmNote}`).then(resp => resp)
    },
    /*eslint-disable*/
    getDoctorListMatchMaking({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/admin/matchmaking/usermatchmaking/list', payload).then(data => data)
    }
}

export default {
    actions
}
