<template>
  <div>

    <!--    <v-toolbar-items v-if="showTopMenu">-->
    <!--      <v-btn class="py-5" text :to="{name:'dashboard'}">{{ $vuetify.lang.t('$vuetify.navDashboard') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'mainPageUserBasedCrm'}">CRM</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'adminSessionRequests'}">درخواست جلسات</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'sessions'}">{{ $vuetify.lang.t('$vuetify.navSessionsLabel') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'clients'}">{{ $vuetify.lang.t('$vuetify.navClientsLabel') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'counselors'}">{{ $vuetify.lang.t('$vuetify.navCounselorsLabel') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'orders'}">{{ $vuetify.lang.t('$vuetify.userOrders') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'coupons'}">کدهای تخفیف</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'giftCodes'}">کد هدیه</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'packageManagement'}">بسته ها</v-btn>-->
    <!--<v-btn class="py-5" text :to="{name:'adminLogger'}">لاگر</v-btn>-->
    <!--<v-btn class="py-5" text :to="{name:'adminFinances'}">-->

    <!--      old menu start from here-->

    <!--    <v-toolbar-items v-if="showTopMenu">-->
    <!--      <v-btn class="py-5" text :to="{name:'dashboard'}">{{ $vuetify.lang.t('$vuetify.navDashboard') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'adminSessionRequests'}">درخواست جلسات</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'sessions'}">{{ $vuetify.lang.t('$vuetify.navSessionsLabel') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'clients'}">{{ $vuetify.lang.t('$vuetify.navClientsLabel') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'counselors'}">{{ $vuetify.lang.t('$vuetify.navCounselorsLabel') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'orders'}">{{ $vuetify.lang.t('$vuetify.userOrders') }}</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'coupons'}">کدهای تخفیف</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'giftCodes'}">کد هدیه</v-btn>-->
    <!--      <v-btn class="py-5" text :to="{name:'packageManagement'}">بسته ها</v-btn>-->
    <!--      &lt;!&ndash;            <v-btn class="py-5" text :to="{name:'adminLogger'}">لاگر</v-btn>&ndash;&gt;-->
    <!--      &lt;!&ndash;<v-btn class="py-5" text :to="{name:'adminFinances'}">&ndash;&gt;-->

    <!--      <v-menu-->
    <!--          open-on-hover-->
    <!--          close-on-content-click-->

    <!--          offset-y-->
    <!--      >-->
    <!--        <template v-slot:activator="{ on, attrs }">-->
    <!--          <v-btn class="py-5" text v-on="on" :to="{name:'finance'}">-->
    <!--            مالی-->
    <!--          </v-btn>-->
    <!--        </template>-->
    <!--        <v-list>-->
    <!--          <v-list-item :to="{name:'adminTransactions'}">-->
    <!--            <v-list-item-title>درخواست ها</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminAddPayment'}">-->
    <!--            <v-list-item-title>افزودن پرداخت</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminPaymentMethods'}">-->
    <!--            <v-list-item-title>متدهای پرداخت</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminFinancialRecords'}">-->
    <!--            <v-list-item-title>رکورد ها</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminFinancialRefund'}">-->
    <!--            <v-list-item-title>استرداد</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminFinancialManageCurrencies'}">-->
    <!--            <v-list-item-title>مدیریت ارز ها</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--        </v-list>-->
    <!--      </v-menu>-->

    <!--      <v-menu-->
    <!--          open-on-hover-->
    <!--          close-on-content-click-->
    <!--          offset-y-->
    <!--      >-->
    <!--        <template v-slot:activator="{ on, attrs }">-->
    <!--          <v-btn class="py-5" text v-on="on" :to="{name:'adminSepidarPayments'}">-->
    <!--            گزارشات-->
    <!--          </v-btn>-->
    <!--        </template>-->
    <!--        <v-list>-->
    <!--          <v-list-item :to="{name:'adminSepidarPayments'}">-->
    <!--            <v-list-item-title>گزارشات مالی سپیدار</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminSepidarSessions'}">-->
    <!--            <v-list-item-title>گزارشات جلسات سپیدار</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminSepidarUsers'}">-->
    <!--            <v-list-item-title>گزارشات کاربران سپیدار</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminMarketingSurvey'}">-->
    <!--            <v-list-item-title>مارکتینگ</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--          <v-list-item :to="{name:'adminMarketingChurn'}">-->
    <!--            <v-list-item-title>نمودار ریزش مشتری</v-list-item-title>-->
    <!--          </v-list-item>-->
    <!--        </v-list>-->
    <!--      </v-menu>-->
    <!--      &lt;!&ndash;</v-btn>&ndash;&gt;-->
    <!--    </v-toolbar-items>-->

    <!--    <v-list v-else-if="showNavIcon" id="adminNavIconItems" dense>-->
    <!--      <v-list-item>-->
    <!--        <v-list-item-avatar>-->
    <!--          <img-->
    <!--              style="object-fit: cover"-->
    <!--              :src="require('./../../assets/avatar-default.png')"/>-->
    <!--        </v-list-item-avatar>-->

    <!--        <v-list-item-title>-->
    <!--          <v-btn text :to="{name:'profile'}">{{-->
    <!--              $store.state.userInfo.firstname + ' ' +-->
    <!--              $store.state.userInfo.lastname-->
    <!--            }}-->
    <!--          </v-btn>-->
    <!--          <p class="secondary&#45;&#45;text px-4" style="font-size: .6rem">مشاهده پروفایل-->
    <!--            <v-icon color="secondary" small>mdi-chevron-left</v-icon>-->
    <!--          </p>-->
    <!--        </v-list-item-title>-->
    <!--      </v-list-item>-->
    <!--      <v-divider></v-divider>-->

    <!--          <template v-for="item in items">-->
    <!--            <v-row-->
    <!--                v-if="item.heading"-->
    <!--                :key="item.heading"-->
    <!--                align="center"-->
    <!--            >-->
    <!--              <v-col cols="6">-->
    <!--                <v-subheader v-if="item.heading">-->
    <!--                  {{ item.heading }}-->
    <!--                </v-subheader>-->
    <!--              </v-col>-->
    <!--              <v-col-->
    <!--                  cols="6"-->
    <!--                  class="text-center"-->
    <!--              >-->
    <!--                <a-->
    <!--                    href="#!"-->
    <!--                    class="body-2 black&#45;&#45;text"-->
    <!--                >EDIT</a>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--            <v-list-group-->
    <!--                v-else-if="item.children"-->
    <!--                :key="item.text"-->
    <!--                v-model="item.model"-->
    <!--                :prepend-icon="item.model ? item.icon : item['icon-alt']"-->
    <!--                append-icon=""-->
    <!--            >-->
    <!--              <template v-slot:activator>-->
    <!--                <v-list-item-content>-->
    <!--                  <v-list-item-title>-->
    <!--                    {{ item.text }}-->
    <!--                  </v-list-item-title>-->
    <!--                </v-list-item-content>-->
    <!--              </template>-->
    <!--              <v-list-item-->
    <!--                  v-for="(child, i) in item.children"-->
    <!--                  :key="i"-->
    <!--                  link-->
    <!--                  class="mx-3"-->
    <!--                  :to="{name:child.routeName}"-->
    <!--              >-->
    <!--                <v-list-item-action v-if="child.icon">-->
    <!--                  <v-icon>{{ child.icon }}</v-icon>-->
    <!--                </v-list-item-action>-->
    <!--                <v-list-item-content>-->
    <!--                  <v-list-item-title>-->
    <!--                    {{ child.text }}-->
    <!--                  </v-list-item-title>-->
    <!--                </v-list-item-content>-->
    <!--              </v-list-item>-->
    <!--            </v-list-group>-->
    <!--            <v-list-item-->
    <!--                v-else-->
    <!--                :key="item.text"-->
    <!--                link-->
    <!--                :to="{name:item.routeName}"-->
    <!--            >-->
    <!--              <v-list-item-action>-->
    <!--                <v-icon>{{ item.icon }}</v-icon>-->
    <!--              </v-list-item-action>-->
    <!--              <v-list-item-content>-->
    <!--                <v-list-item-title>-->
    <!--                  {{ item.text }}-->
    <!--                </v-list-item-title>-->
    <!--              </v-list-item-content>-->
    <!--            </v-list-item>-->
    <!--          </template>-->
    <!--        </v-list>-->


    <v-list id="adminNavIconItems" v-if="$vuetify.breakpoint.smAndDown">
      <v-list-item>
        <v-list-item-avatar>
          <img
              style="object-fit: cover"
              :src="require('./../../assets/avatar-default.png')"/>
        </v-list-item-avatar>

        <v-list-item-title>
          <v-btn text @click="toProfile">
            {{
              $store.state.userInfo.firstname + ' ' +
              $store.state.userInfo.lastname
            }}
          </v-btn>
          <p @click="toProfile" class="secondary--text px-4" style="font-size: .6rem">مشاهده پروفایل
            <v-icon color="secondary" small>mdi-chevron-left</v-icon>
          </p>
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <!--          <template v-for="(item , i) in items">-->
      <!--            <v-menu :key="i"-->
      <!--                   open-on-click-->
      <!--                    close-on-content-click-->
      <!--                    offset-x-->
      <!--            >-->
      <!--              <template v-slot:activator="{ on, attrs }" >-->
      <!--                <v-btn class="py-5" text v-on="on"   v-if="checkRoleAndPermissions(item.requiredPermission)">-->
      <!--                  {{item.text}}-->
      <!--                </v-btn>-->
      <!--              </template>-->
      <!--              <v-list v-if="item.children">-->
      <!--                <template v-for="(item,i) in item.children" >-->
      <!--                  <v-list-item  :to="{name:item.routeName}" :key="i" v-if="checkRoleAndPermissions(item.requiredPermission)">-->
      <!--                    <v-list-item-title >{{item.text}}</v-list-item-title>-->
      <!--                  </v-list-item>-->
      <!--                </template>-->
      <!--              </v-list>-->

      <!--            </v-menu>-->
      <!--          </template>-->


      <template v-for="item in items">
        <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center"
        >
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col
              cols="6"
              class="text-center"
          >
            <a
                href="#!"
                class="body-2 black--text"
            >EDIT</a>
          </v-col>
        </v-row>
        <template v-else-if="item.children">
          <v-list-group
              v-if="checkRoleAndPermissions(item.requiredPermission)"
              :key="item.text"
              v-model="item.model"
              append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-if="checkRoleAndPermissions(item.requiredPermission)">
              <template v-for="(child, i) in item.children">
                <v-list-item
                    :key="i"
                    link
                    class="mx-3"
                    :to="{name:child.routeName}"
                    v-if="checkRoleAndPermissions(child.requiredPermission)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ child.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
              v-if="checkRoleAndPermissions(item.requiredPermission)"
              :key="item.text"
              link
              :to="{name:item.routeName}"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

      </template>
    </v-list>

    <!--      old menu start from here-->


    <v-toolbar-items v-if="showTopMenu">
      <template v-for="(item , i) in items">
        <v-menu :key="i"
                open-on-hover
                close-on-content-click
                offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="py-5" text v-on="on" :to="{name:item.routeName}"
                   v-if="checkRoleAndPermissions(item.requiredPermission)">
              {{ item.text }}
              <!--                <div class="nav-custom-badge text-center" v-if="item.badgeKey && $store.getters.getAdminDashboard[item.badgeKey]!=0" ></div>-->
            </v-btn>
          </template>

          <v-list v-if="item.children">
            <template v-for="(item,i) in item.children">
              <v-list-item :to="{name:item.routeName}" :key="i"
                           v-if="checkRoleAndPermissions(item.requiredPermission)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </template>

          </v-list>
        </v-menu>
      </template>

    </v-toolbar-items>
  </div>
</template>

<script>

export default {
  data() {
    return {
      items: [
        {
          routeName: 'dashboard',
          icon: 'mdi-view-dashboard',
          text: 'داشبورد'
        },
        // {
        //   routeName: 'mainPageUserBasedCrm',
        //   icon: 'mdi-view-dashboard',
        //   text: 'CRM',
        //   requiredPermission: 'admin.crm.panel',
        // },

        // {
        //   routeName: 'matchMakingRoutes',
        //   icon: 'mdi-view-dashboard',
        //   text: 'تشخیص',
        //   requiredPermission: ['admin.matchmaking.view'],
        //   children: [
        //     {
        //       routeName: 'matchMakingTests',
        //       icon: 'mdi-view-dashboard',
        //       text: 'مدریت تست‌ها',
        //       requiredPermission: '',
        //     },
        //     {
        //       routeName: 'MatchMakingResult',
        //       icon: 'mdi-view-dashboard',
        //       text: 'تشخیص',
        //       requiredPermission: '',
        //     },
        //     // {
        //     //   routeName: 'assignDoctor',
        //     //   icon: 'mdi-view-dashboard',
        //     //   text: 'تخصیص دکتر',
        //     //   requiredPermission: '',
        //     // },
        //   ]
        // },
        {
          routeName: 'aclRoutes',
          icon: 'mdi-view-dashboard',
          text: 'دسترسی',
          requiredPermission: [],
          children: [
            {
              routeName: 'RolesACLView',
              icon: 'mdi-view-dashboard',
              text: 'نقش‌ها',
              requiredPermission: '',
            },
            {
              routeName: 'PermissionACLView',
              icon: 'mdi-view-dashboard',
              text: 'سطوح دسترسی',
              requiredPermission: '',
            },
            {
              routeName: 'UsersACLView',
              icon: 'mdi-view-dashboard',
              text: 'مدیران',
              requiredPermission: '',
            },
          ]
        },
        // {
        //   routeName: 'assignDoctor',
        //   icon: 'mdi-view-dashboard',
        //   text: 'تخصیص دکتر',
        //   requiredPermission: '',
        // },

        // {
        //   routeName: 'ChooseClientSalesStuff',
        //   icon: 'mdi-view-dashboard',
        //   text: 'انتخاب لید',
        //   badgeKey: 'unassignedLeads',
        //   requiredPermission: 'admin.crm.seller',
        // },
        // {
        //   routeName: 'calendarCrm',
        //   icon: 'mdi-view-calendar',
        //   text: 'تقویم',
        // },
        {
          routeName: 'adminSessionRequests',
          icon: 'mdi-calendar-question',
          text: 'درخواست جلسات',
          requiredPermission: 'sessions.read',
        },
        {
          routeName: 'sessions',
          icon: 'mdi-calendar',
          text: 'جلسات',
          requiredPermission: 'sessions.read'
        },
        {
          routeName: 'clients',
          icon: 'mdi-a ccount-group',
          text: 'مراجعان',
          requiredPermission: 'admin.clients.view'
        },
        {
          routeName: 'counselors',
          icon: 'mdi-doctor',
          text: 'مشاوران',
          requiredPermission: 'admin.doctors.search',
        },
        {
          routeName: 'orders',
          icon: 'mdi-cash',
          text: 'سفارشات',
          requiredPermission: 'admin.cart.transactions.read',
        },
        {
          routeName: "coupons",
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'کدهای تخفیف',
          requiredPermission: "admin.coupons.search",
          model: false,

        },
        // {
        //   routeName: "giftCodes",
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'کدهای هدیه ',
        //   requiredPermission: 'admin.giftcodes.read',
        //   model: false,
        // },
        {
          routeName: "packageManagement",
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'بسته ها',
          requiredPermission: "admin.packages.search",
          model: false,
        },
        {
          routeName: "finance",
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'مالی',
          model: false,
          requiredPermission: [
            'admin.transactions.requests',
            'admin.transactions.add',
            'admin.transactions.addInvoice',
            'admin.paymentmethods.read',
            "admin.financialrecords.read",
            'admin.transactions.refunds.requests',
            'admin.currencies.read',
            "admin.financial.counselorPayments.read"
          ],
          children: [
            {
              routeName: 'adminTransactions',
              icon: 'mdi-file-search-outline',
              text: 'درخواست ها',
              requiredPermission: 'admin.transactions.requests'
            },
            {
              routeName: 'adminAddPayment',
              icon: 'mdi-plus',
              text: 'افزودن پرداخت',
              requiredPermission: 'admin.transactions.add'
            },
            // {
            //   routeName: 'addInvoice',
            //   icon: 'mdi-plus',
            //   text: 'افزودن فاکتور',
            //   requiredPermission: 'admin.transactions.addInvoice'
            // },
            {
              routeName: 'adminPaymentMethods',
              icon: 'mdi-format-list-bulleted',
              text: 'متدهای پرداخت',
              requiredPermission: 'admin.paymentmethods.read'
            },
            {
              routeName: 'adminFinancialRecords',
              icon: 'mdi-format-list-bulleted',
              text: 'رکورد ها',
              requiredPermission: "admin.financialrecords.read"
            },
            {
              routeName: 'adminFinancialRefund',
              icon: 'mdi-format-list-bulleted',
              text: ' استرداد',
              requiredPermission: 'admin.transactions.refunds.requests'
            },
            {
              routeName: 'adminFinancialManageCurrencies',
              icon: 'mdi-format-list-bulleted',
              text: ' مدیریت ارز‌ها',
              requiredPermission: 'admin.currencies.read'
            },
            {
              routeName: 'webHookShopify',
              icon: 'mdi-view-dashboard',
              text: 'پرداخت‌های مستقیم شاپیفای',
              requiredPermission: 'admin.webhook_shopify'
            },
            {
              routeName: 'counselorPayments',
              icon: 'mdi-view-dashboard',
              text: 'پرداخت‌های مشاوران',
              requiredPermission: "admin.financial.counselorPayments.read"
            },
          ],
        },
        {
          routeName: 'adminSepidarPayments',
          icon: 'mdi-view-dashboard',
          text: 'گزارشات',
          requiredPermission: [
            'admin.export.reports.sepidar',
            'admin.marketing.users',
            'admin.churn'
          ],
          children: [
            {
              routeName: 'adminSepidarPayments',
              icon: 'mdi-view-dashboard',
              text: 'گزارشات مالی سپیدار',
              requiredPermission: 'admin.export.reports.sepidar',
            },
            // {
            //   routeName: 'SesseionChart',
            //   icon: 'mdi-view-dashboard',
            //   text: 'نمودار جلسات',
            //   requiredPermission: 'admin.export.reports.sepidar',
            // },
            // {
            //   routeName: 'PaymentChart',
            //   icon: 'mdi-view-dashboard',
            //   text: 'نمودار پرداخت',
            //   requiredPermission: 'admin.export.reports.sepidar',
            // },
            {
              routeName: 'adminSepidarSessions',
              icon: 'mdi-view-dashboard',
              text: 'گزارشات جلسات سپیدار',
              requiredPermission: 'admin.export.reports.sepidar',
            },
            {
              routeName: 'adminSepidarUsers',
              icon: 'mdi-view-dashboard',
              text: 'گزارشات کاربران سپیدار',
              requiredPermission: 'admin.export.reports.sepidar',
            },
            {
              routeName: 'adminUserReport',
              icon: 'mdi-view-dashboard',
              text: 'گزارشات کاربران',
              requiredPermission: 'admin.export.reports.sepidar',
            },
            {
              routeName: 'adminMarketingSurvey',
              icon: 'mdi-view-dashboard',
              text: 'مارکتینگ',
              requiredPermission: 'admin.marketing.users',
            },
            {
              routeName: 'UserMonitoring',
              icon: 'mdi-view-dashboard',
              text: 'مانیتورینگ‌ کاربران',
              requiredPermission: 'admin.reports.user_monitoring',
            },
            {
              routeName: 'presessionReport',
              icon: 'mdi-view-dashboard',
              text: 'گزارش پیش‌مشاوره',
              requiredPermission: 'admin.reports.presession',
            },
            {
              routeName: 'adminMarketingChurn',
              icon: 'mdi-view-dashboard',
              text: 'نمودار ریزش مشتری',
              requiredPermission: 'admin.churn'
            },
            {
              routeName: 'adminAverageSessionReport',
              icon: 'mdi-view-dashboard',
              text: 'نمودار میانگین جلسات',
              requiredPermission: 'admin.session_avg'
            },
            {
              routeName: 'adminWhatsappLog',
              icon: 'mdi-view-dashboard',
              text: 'گزارشات واتساپ',
              requiredPermission: 'admin.whatsapp'
            },

          ]
        },


        // {
        //       icon: 'mdi-chevron-up',
        //       'icon-alt': 'mdi-chevron-down',
        //       text: 'سپیدار',
        //       model: false,
        //       children: [
        //           {routeName: 'adminSepidarPayments', icon: 'mdi-cash', text: 'گزارشات مالی'},
        //           {
        //               routeName: 'adminSepidarUsers',
        //               icon: 'mdi-account-group-outline',
        //               text: 'گزارشات کاربران'
        //           },
        //           {
        //               routeName: 'adminSepidarSessions',
        //               icon: 'mdi-monitor-multiple',
        //               text: 'گزارشات جلسات'
        //           },
        //       ],
        //   },
        // {
        //     icon: 'mdi-chevron-up',
        //     'icon-alt': 'mdi-chevron-down',
        //     text: 'مارکتینگ',
        //     model: false,
        //     children: [
        //         {
        //             routeName: 'adminMarketingSurvey',
        //             icon: 'mdi-account-group-outline',
        //             text: 'مارکتینگ'
        //         },
        //         {
        //             routeName: 'adminMarketingChurn',
        //             icon: 'mdi-monitor-multiple',
        //             text: 'نمودار ریزش مشتری'
        //         },
        //     ],
        // },
      ],
    }
  },
  computed: {
    showNavIcon() {
      // return true;
      return this.$vuetify.breakpoint.mdAndDown;
    },
    showTopMenu() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  mounted() {
    // console.log(this.$store.getters.getUserInfo)
  },
  methods: {
    toProfile() {
      this.$router.push({name: 'profile'})
    }
  }
}
</script>

<style>
#adminNavIconItems .v-btn__content {
  justify-content: right;
}

.nav-custom-badge {
  position: absolute;
  left: calc(50% - 5px);
  bottom: -15px;
  width: 10px;
  height: 10px;
  color: white;
  background-color: red;
  border-radius: 30px;

}
</style>
