/*eslint-disable*/
import MainLayout from '../views/layouts/MainLayout.vue'
import PanelLayout from '../views/layouts/PanelLayout.vue'
import Home from '../views/Home'
import AdminSessionRequests from '../views/admin/SessionRequests'
import AdminAssignment from '../views/admin/AdminAssignment'
import Dashboard from '../views/common/Dashboard.vue'
import Messenger from '../views/common/Messenger.vue'
import Sessions from '../views/common/Sessions'
// import JoinSessionView from '../views/common/JoinSessionView'
import StartSession from "../views/common/StartSession";
import Clients from '../views/common/Clients.vue'
import Counselors from '../views/admin/Counselors.vue'
// import Financial from '../views/admin/Financial.vue'
import SessionDetails from '../views/admin/SessionDetails.vue'
import Orders from '../views/common/Orders.vue'
import Profile from '../views/common/Profile.vue'

// import clientPricing from "../views/client/clientPricing";
// import ClientFactors from "../views/client/ClientFactors";
// import clientCart from "../views/client/clientCart";
// import userWallet from "../views/client/userWallet";
// import AssignConsult from "../views/common/AssignConsult";
// import Cycles from "../views/client/Cycles";
// import Wallet from "../views/client/Wallet";


import Requests from "../views/admin/Requests";
import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue'
import ClientProfile from "../components/clients/ClientProfile";
import CounselorProfile from "../views/admin/CounselorProfile";
import AddNewClient from "../views/admin/AddNewClient";
import AdminManageCoupons from "../views/admin/AdminManageCoupons";
import AdminGiftCodes from "../views/admin/AdminGiftCodes";
import AdminSingleFactor from "../views/admin/AdminSingleFactor";
import AdminManagePackages from "../views/admin/AdminManagePackages";
import AdminAddNewCoupons from "../views/admin/AdminAddNewCoupons";
import AdminAddNewGiftCode from "../views/admin/AdminAddNewGiftCode";
import AdminAddNewPackages from "../views/admin/AdminAddNewPackages";
import ClientDetail from "../views/admin/ClientDetail";
import UserCalendar from "../views/admin/UserCalendar";

// import ClientSubmittedOrPartialCart from '../views/client/clientSubmittedOrPartialCart'
import AdminClientCalendarPage from "@/views/admin/adminClient/AdminClientCalendarPage.vue";
// import AdminClientCalendar from "@/components/Admin/calendar/calendar.vue";
// import clientProfile from "../components/profiles/clientProfile";
import EmailVerify from "../components/EmailVerify";
import AdminFinancialSection from "../views/admin/AdminFinancialSection";
// import AdminFinancialAddNewPayment from "../views/admin/AdminFinancialAddNewPayment";
import AdminFinancialAddPayment from "../views/admin/AdminFinancialAddPayment";
import AdminFinancialTransactions from "../views/admin/AdminFinancialTransactions";
import adminFinancialRecords from "../views/admin/adminFinancialRecords";
import AdminFinancialRefund from "../views/admin/financials/AdminFinancialRefund";

import AdminLogger from "../views/admin/AdminLogger";

import AdminExpressEntry from "../views/admin/adminClient/AdminExpressEntryPage";

import AdminPaymentMethods from "../views/admin/AdminPaymentMethods";
import AdminManageCurrencies from "../views/admin/AdminManageCurrencies";
// import ExpressSession from "../views/client/ExpressSession";
import Temp from '../views/Temp';

import AdminSepidarReports from '../views/admin/Sepidar/AdminSepidarReports'
import AdminSepidarPaymentReports from '../views/admin/Sepidar/AdminSepidarPaymentReports'
import AdminSepidarUserReports from '../views/admin/Sepidar/AdminSepidarUserReports'
import AdminSepidarSessionReports from '../views/admin/Sepidar/AdminSepidarSessionReports'
import AdminMarketingSurvey from "../views/admin/Sepidar/AdminMarketingSurvey";
import AdminMarketingChurn from "../views/admin/marketing/AdminMarketingChurn";
import layoutCRM from "@/views/admin/crm/layoutCRM";
import managementAreaCrm from "@/views/admin/crm/managementAreaCrm";
import taskLoadArea from "@/views/admin/crm/sectionManager/taskLoadArea";
import dashboardSelectSalesStuff from "@/views/admin/crm/salesStuff/dashboardSelectSalesStuff";
import CancelSeessionCrm from "@/components/crm/cancelSeessionCrm";
import * as path from "path";
import mainLayoutCrm from "@/views/admin/crm/mainLayoutCrm";
import LayoutCRM from "@/views/admin/crm/layoutCRM";
import taskManagerIndex from "@/views/admin/crm/taskManager/taskManagerIndex";
import MatchMakingResult from "@/views/admin/MatchMakingResult";
// import AssignDoctor from "@/components/Admin/AssignDoctor";
import calendarView from "@/views/admin/calendar/calendarView";
import AdminInvoice from "@/views/admin/AdminInnvoice";
import MatchMakingTest from '@/views/admin/MatchMaking/MatchMakingTests'
import MatchMakingQuestions from "@/views/admin/MatchMaking/MatchMakingQuestions";
import MatchMakingOptions from "@/views/admin/MatchMaking/MatchMakingOptions";
import AssignDoctor from "@/components/Admin/AssignDoctor";
import RolesView from "@/views/admin/ACL/RolesView";
import PermissionView from "@/views/admin/ACL/PermissionView";
import UserManagementView from "@/views/admin/ACL/UserManagementView";
import BaseAcl from "@/views/admin/ACL/BaseAcl";
import MatchMakingBase from "@/views/admin/MatchMaking/MatchMakingBase";
import Timebook_calander from "@/components/calendar/timebook_calander";
import NotificationView from "@/views/admin/NotificationView";
import AdminWhatsappLog from "@/views/admin/AdminWhatsappLog";
import AdminUserReports from "@/views/admin/AdminUserReports";
import AdminUserMonitoring from "@/views/admin/AdminUserMonitoring";
import MarketingPresessionReport from "@/views/admin/MarketingPresessionReport";
import clientHistory from "@/components/clients/clientHistory";
import SessionChart from "@/views/admin/Chart/SessionChart";
import PaymentChart from "@/views/admin/Chart/PaymentChart";
import AdminWebHookShopify from "@/views/admin/AdminWebHookShopify";
import AdminSessionAvg from "@/views/admin/marketing/AdminSessionAvg";
import AdminDoctorUserList from "@/views/admin/AdminDoctorUserList";
import AdminFinancialCounselors from "@/views/admin/AdminFiancialCounselors.vue"
import CounselorPatients from "@/components/clients/CounselorPatients.vue";


const clientRole = 'customer';
const doctorRole = 'counselor';

const panelRoutes = [
    {
        path: '/',
        redirect: 'dashboard',
    },

    {
        // path: '/',
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'calendar',
        component: calendarView,
        name: 'calendarCrm',
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
            requiresGuest: true
        }
    },

    {
        path: 'assign-doctor',
        name: 'assignDoctor',
        component: AssignDoctor,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            // requiredPermission: 'admin.cart.transactions.read'
        }
    },
    {
        path: 'orders',
        name: 'orders',
        component: Orders,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.cart.transactions.read'
        }
    },
    {
        path: 'orders/:factorId',
        name: 'singleOrder',
        component: AdminSingleFactor,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.cart.transactions.show'
        }
    },
    // {
    //     path: 'admin-factors',
    //     name: 'adminFactors',
    //     component: AdminFactors,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'finance',
    //     name: 'adminFinancial',
    //     component: Financial,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },

    {
        path: 'match-making-result',
        name: 'MatchMakingResult',
        component: MatchMakingResult,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'clients',
        name: 'clients',
        component: Clients,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.clients.view'
        }
    },
    {
        path: 'nocycle',
        name: 'nocycle',
        component: Clients,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'notime',
        name: 'notime',
        component: Clients,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'sessionrequests',
        name: 'adminSessionRequests',
        component: AdminSessionRequests,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: "sessions.read"
        }
    },
    {
        path: 'assignment/user/:userId/cycle/:cycleId/request/:requestId',
        name: 'adminAssignment',
        component: AdminAssignment,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'sessions.suggest.time'
        }
    },
    {
        path: 'clients/:clientId',
        name: 'clientDetail',
        component: ClientDetail,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'clients.information'
        }
    },
    {
        path: 'clients/:clientId/profile',
        name: 'clientProfile',
        component: ClientProfile,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'clients/:clientId/match-history',
        name: 'clientHistory',
        component: clientHistory,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'messenger',
        name: 'messenger',
        component: Messenger,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    // {
    //     path: 'clients/:clientId/calendar',
    //     name: 'clientCalendar',
    //     component: UserCalendar,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     // path: 'clients',
    //     // component: Login,
    //     // meta: {
    //     //     requiresAuth: true,
    //     //     requiredRoles: ['admin', doctorRole]
    //     // },
    //     // children: [
    //     //
    //     //
    //     // ]
    // },
    {
        path: 'counselors',
        name: 'counselors',
        component: Counselors,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.doctors.search'
        }
    },
    {
        path: 'notification',
        name: 'notification',
        component: NotificationView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.doctors.search'
        }
    },
    {
        path: 'counselors/add-counselor',
        name: 'addCounselors',
        component: CounselorProfile,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'add-client',
        name: 'addNewClient',
        component: AddNewClient,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.user.create'
        }
    },
    {
        path: 'coupons',
        name: 'coupons',
        component: AdminManageCoupons,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.coupons.search'
        }
    },
    {
        path: 'add-gift-code',
        name: 'addNewGiftCode',
        component: AdminAddNewGiftCode,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.giftcodes.add'
        }
    },
    {
        path: 'gift-codes',
        name: 'giftCodes',
        component: AdminGiftCodes,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.giftcodes.read'
        }
    },
    {
        path: 'express-entry',
        name: 'express-entry',
        component: AdminExpressEntry,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },

    {
        path: 'finance',
        name: 'finance',
        component: AdminFinancialSection,
        redirect: {
            name: "adminTransactions"
        },
        children: [
            {
                path: 'transactions',
                name: 'adminTransactions',
                component: AdminFinancialTransactions,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin']
                }
            }, {
                path: 'records',
                name: 'adminFinancialRecords',
                component: adminFinancialRecords,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: "admin.financialrecords.read"
                }
            },
            {
                path: 'paymentmethods',
                name: 'adminPaymentMethods',
                component: AdminPaymentMethods,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.paymentmethods.read'
                }
            },
            {
                path: 'add-payment',
                name: 'adminAddPayment',
                component: AdminFinancialAddPayment,

                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.transactions.add',
                }
            },
            {
                path: 'add-invoice',
                name: 'addInvoice',
                component: AdminInvoice,

                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.transactions.addInvoice',
                }
            },
            {
                path: 'refunds',
                name: 'adminFinancialRefund',
                component: AdminFinancialRefund,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.transactions.refunds.requests',
                }
            },
            {
                path: 'currencies',
                name: 'adminFinancialManageCurrencies',
                component: AdminManageCurrencies,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.currencies.read'
                }
            },
            {
                path: 'webhook_shopify',
                name: 'webHookShopify',
                component: AdminWebHookShopify,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.webhook_shopify'
                }
            },
            {
                path: 'counselor-payments',
                name: 'counselorPayments',
                component: AdminFinancialCounselors,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.counselorPayment.read'
                }
            },
        ]
    },

    {
        path: 'reports',
        name: 'reports',
        component: AdminSepidarReports,
        redirect: {
            name: "adminSepidarReports"
        },
        children: [
            {
                path: 'payments',
                name: 'adminSepidarPayments',
                component: AdminSepidarPaymentReports,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.export.reports.sepidar'
                }
            },
            {
                path: 'session-chart',
                name: 'SesseionChart',
                component: SessionChart,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.export.reports.session_chart'
                }
            },
            {
                path: 'payment-chart',
                name: 'PaymentChart',
                component: PaymentChart,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.export.reports.session_chart'
                }
            },
            {
                path: 'user-monitoring',
                name: 'UserMonitoring',
                component: AdminUserMonitoring,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.reports.user_monitoring'
                }
            },
            {
                path: 'users-journey',
                name: 'adminUserReport',
                component: AdminUserReports,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.export.reports.sepidar'
                }
            },
            {
                path: 'presession-report',
                name: 'presessionReport',
                component: MarketingPresessionReport,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.export.reports.presession'
                }
            },
            {
                path: 'whatsapp',
                name: 'adminWhatsappLog',
                component: AdminWhatsappLog,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    // requiredPermission: 'admin.export.reports.sepidar'
                }
            },
            {
                path: 'sessions',
                name: 'adminSepidarSessions',
                component: AdminSepidarSessionReports,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.export.reports.sepidar'
                }
            },
            {
                path: 'users',
                name: 'adminSepidarUsers',
                component: AdminSepidarUserReports,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.export.reports.sepidar'
                }
            },
            {
                path: 'marketing',
                name: 'adminMarketingSurvey',
                component: AdminMarketingSurvey,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin']
                }
            },
            {
                path: 'marketingChurn',
                name: 'adminMarketingChurn',
                component: AdminMarketingChurn,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.churn'
                }
            },
            {
                path: 'average-session-report',
                name: 'adminAverageSessionReport',
                component: AdminSessionAvg,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin'],
                    requiredPermission: 'admin.session_avg'
                }
            },
            // {
            //     path: 'add-payment',
            //     name: 'adminAddPayment',
            //     component: AdminFinancialAddNewPayment,
            //     meta: {
            //         requiresAuth: true,
            //         requiredRoles: ['admin']
            //     }
            // },
            {
                path: 'refunds',
                name: 'adminFinancialRefund',
                component: AdminFinancialRefund,
                meta: {
                    requiresAuth: true,
                    requiredRoles: ['admin']
                }
            },
        ]
    },
    {
        path: 'packages',
        name: 'packageManagement',
        component: AdminManagePackages,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.packages.search'
        }
    },
    {
        path: 'add-coupons',
        name: 'addNewCoupons',
        component: AdminAddNewCoupons,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.coupons.add'
        }
    },
    {
        path: 'add-packages',
        name: 'addNewPackage',
        component: AdminAddNewPackages,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'logger',
        name: 'adminLogger',
        component: AdminLogger,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },

    {
        path: 'counselors/:counselorId/profile',
        name: 'counselorProfile',
        component: CounselorProfile,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.doctors.show'
        }
    },
    {
        path: 'counselors/:counselorId/patients',
        name: 'counselorPatients',
        component: CounselorPatients,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
         }
    },
    {
        path: 'counselors/:counselorId/user-list',
        name: 'counselorUserList',
        component: AdminDoctorUserList,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'admin.doctors.user-list'
        }
    },
    {
        path: 'counselors/:counselorId/calendar',
        name: 'counselorCalendar',
        component: Timebook_calander,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'doctors.calendar.read'
        }
    },
    {
        path: 'calendar/presession',
        name: 'preSessionCalendar',
        component: Timebook_calander,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: 'doctors.calendar.read'
        }
    },
    {
        path: 'clients/:clientId/calendar',
        name: 'adminClientCalendar',
        component: AdminClientCalendarPage,
        // name: 'clientCalendar',
        // component: UserCalendar,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    // {
    //     path: 'counselors',
    //     children: [
    //
    //
    //
    //     ]
    // },
    {
        path: 'sessions',
        name: 'sessions',
        component: Sessions,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin'],
            requiredPermission: "sessions.show"

        }
    },
    {
        path: 'sessions/:date',
        name: 'dateSessions',
        component: Sessions,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'requests',
        name: 'requests',
        component: Requests,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },

    {
        path: 'sessions/start/:sessionId',
        name: 'startsession',
        component: StartSession,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'sessions/:sessionId/details',
        name: 'adminSessionDetails',
        component: SessionDetails,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin']
        }
    },
    {
        path: 'crm',
        component: mainLayoutCrm,
        name: "CrmMainLayout",
        children: [
            {
                path: 'users',
                name: 'mainPageUserBasedCrm',
                component: LayoutCRM,
                children: [
                    {
                        path: ':clientId',
                        component: managementAreaCrm,
                        name: 'CrmUserInfo',
                    },
                    {
                        path: ':clientId/cancel-session',
                        component: CancelSeessionCrm,
                        name: 'cancelSessionCrm',
                    },
                    {
                        path: ':clientId/cancel-session',
                        component: CancelSeessionCrm,
                        name: 'cancelSessionCrm',
                    }
                ]
            },
            {
                path: 'management',
                name: "mainPageTaskBasedCrm",
                component: taskManagerIndex,
            }
        ]
    },
    {
        path: 'match-making',
        component: MatchMakingBase,
        name: 'matchMakingRoutes',
        children: [
            {
                path: "tests",
                component: MatchMakingTest,
                name: 'matchMakingTests'
            },
            {
                path: ":testId/questions",
                component: MatchMakingQuestions,
                name: 'matchMakingQuestions'
            },
            {
                path: ":testId/questions/:optionId/option",
                component: MatchMakingOptions,
                name: 'matchMakingOption'
            },
        ]
    },
    {
        path: "crm/choose-client",
        component: dashboardSelectSalesStuff,
        name: "ChooseClientSalesStuff",
    },

    {
        path: 'acl',
        name: 'aclRoutes',
        component: BaseAcl,
        children: [
            {
                path: 'roles',
                component: RolesView,
                name: 'RolesACLView',
            },
            {
                path: 'permission',
                component: PermissionView,
                name: 'PermissionACLView',
            },
            {
                path: 'users',
                component: UserManagementView,
                name: 'UsersACLView',
            },
        ]
    }

    // {
    //     path: 'sessions',
    //     // component: Login,
    //     children: [
    //
    //     ]
    // },
    // {
    //     path: 'sessions/:id',
    //     name: 'sessionInfo',
    //     component: Login,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [doctorRole, 'admin', clientRole]
    //     }
    // },
    // {
    //     path: 'sessions/:id/feedback',
    //     name: 'sessionFeedback',
    //     component: Login,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [doctorRole, clientRole]
    //     }
    // },
    // {
    //     path: 'sessions/reserve',
    //     name: 'reserveSession',
    //     component: Login,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [clientRole]
    //     }
    // },
    // {
    //     path: 'calendar',
    //     name: 'calendar',
    //     component: Calendar,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [doctorRole, clientRole]
    //     }
    // },
    // {
    //     path: 'cycle/info',
    //     name: 'cycleInfo',
    //     component: Login,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [clientRole]
    //     }
    // }


];

const landingPages = [];

const routes = [
    // {
    //     path: "/:lang",
    //     redirect: {
    //         name: "login"
    //     }
    // },
    // {
    //     path: "/:lang",
    //     // redirect: to => {
    //     //     if(!to.path.includes('emailverify')) {
    //     //         return {name: 'login'}
    //     //     }else {
    //     //         return {name: 'emailverify'}
    //     //     }
    //     // }
    // },
    {
        path: '/password/reset/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/emailverify/:token',
        name: 'emailverify',
        component: EmailVerify,
    },
    // {
    //     path: '/preorder/:orderId',
    //     name: 'preorder',
    //     component: PreOrder
    // },
    // {
    //     path: '/expresssession',
    //     name: 'expressSession',
    //     component: ExpressSession
    // },
    // {
    //     path: '/join-session/:sessionCode',
    //     name: 'joinSession',
    //     component: JoinSessionView,
    //     meta: {
    //         requiresGuest: true
    //     }
    // },
    // {
    //     path: '/assignconsult',
    //     name: 'preassignconsult',
    //     component: AssignConsult
    // },
    // {
    //     path: '/temppath',
    //     name: 'temppath',
    //     component: Temp,
    // },
    {
        path: '/temppath',
        name: 'temp',
        component: calendarView
    },
    {
        path: '/:lang',
        name: 'lang',
        component: MainLayout,
        children: [
            {
                path: '/',
                redirect: '/fa/panel/login'
            },
            {
                path: 'panel',
                name: 'panel',
                component: PanelLayout,
                children: panelRoutes
            },
            {
                path: '/',
                name: 'home',
                component: Home,
                children: landingPages
            },
            {
                path: '*',
                redirect: '/'
            }

        ]
    }
];

export default routes
