<template>
  <div v-if="rerender">
    <v-container fluid>
      <v-card class="my-8">
        <v-progress-linear
            indeterminate
            :active="requesting"
            color="blue"
        ></v-progress-linear>
        <div class="d-flex " :class="$vuetify.breakpoint.mdAndUp && 'justify-right'" style="width: 100%">
          <div v-for="(month , i) in months" :key="i">
            <v-btn class="ma-1 mt-3" small elevation="0" :disabled="loading"
                   @click="getMonthStaticAndTargets(month.start,month.end,month.id,month.addYear)"
                   :loading="loading && selectedMonth ==month.id"
                   :class="{'blue white--text': selectedMonth == month.id }">
              {{ month.label }}
            </v-btn>
          </div>
        </div>
        <v-card-text>
          <TableOptions
              :hasExport="true"
              :searchFilters="searchFilters"
              @applyFilters="applyFilters"
              :action-required-permission="{ download:'admin.counselorPayment.download' }"
          ></TableOptions>
        </v-card-text>

        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="sessions">
          <template v-slot:item="{item}">
            <tr>
              <td>
                <v-chip style="overflow:visible;cursor: pointer">
                  <div @click="getClientProfile(item.user.id)">
                    {{ item.user.full_name }}
                  </div>
                </v-chip>
              </td>
              <td>
                <template>
                  {{ item.fixed_date }}
                </template>
              </td>

              <td>
                <template>
                  {{ item.fixed_time }}
                </template>

              </td>
              <td>
                <template>
                  {{ item.doctor.full_name }}
                </template>
              </td>

              <td>
                <div style="direction: ltr;text-align: right">
                  <template v-if="item.reason == 'extra_session_time'">
                    <v-chip x-small>
                      بابت تسویه
                    </v-chip>
                  </template>
                  {{ item.extra_duration }}

                </div>
              </td>

              <td>

                <div style="direction: ltr;text-align: right">
                  <template>
                    {{ item.doctor_payment_rial }}
                  </template>
                </div>
              </td>
              <td>
                <div style="direction: ltr;text-align: right">
                  <template>
                    {{ item.doctor_payment_dollar }}
                  </template>
                </div>
              </td>

              <td>
                <div style="direction: ltr;text-align: right">
                  <template>
                    {{ item.currency }}
                  </template>
                </div>
              </td>

              <td>
                <div style="direction: ltr;text-align: right">
                  <template>
                    {{ item.package_title }}
                  </template>
                </div>
              </td>
              <td>
                <div style="direction: ltr;text-align: right">
                  <div v-if="item.cart_id">
                    <a :href="'/fa/panel/orders/'+item.cart_id"
                       @click.prevent="$router.push({name:'singleOrder',params:{factorId:item.cart_id}})"
                       v-if="checkRoleAndPermissions('admin.cart.transactions.show')">
                      <v-btn fab x-small>
                        <v-icon small>mdi-view-headline</v-icon>
                      </v-btn>
                    </a>
                  </div>
                </div>
              </td>

            </tr>

          </template>
        </v-data-table>
      </v-card>
      <div class="text-center">
        <v-pagination
            @input="handlerPagination(getAdminSessions)"
            v-model="page"
            :length="total"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from 'vuex';
import moment from 'moment-jalaali';

import {EventBus} from "../../event-bus";
import {
  adminCounselorPaymentFilters
} from "../../searchFilters";
import TableOptions from '@/components/TableOptions.vue';
import UrlQueryMixin from "@/Mixin/UrlQueryMixin";

export default {
  mixins: [UrlQueryMixin],
  components: {
    TableOptions
  },
  data() {
    return {
      loading: false,
      selectedMonth: null,
      months: [

        {
          id: 1,
          label: 'فروردین',
          start: '01-01',
          end: '01-31',
        },
        {
          id: 2,
          label: 'اردبیهشت',
          start: '02-01',
          end: '02-31',
        },
        {
          id: 3,
          label: 'خرداد',
          start: '03-01',
          end: '03-31',
        },
        {
          id: 4,
          label: 'تیر',
          start: '04-01',
          end: '04-31',
        },
        {
          id: 5,
          label: 'مرداد',
          start: '05-01',
          end: '05-31',
        },
        {
          id: 6,
          label: 'شهریور',
          start: '06-01',
          end: '06-31',
        },
        {
          id: 7,
          label: 'مهر',
          start: '07-01',
          end: '07-30',
        },
        {
          id: 8,
          label: 'آبان',
          start: '08-01',
          end: '08-30',
        },
        {
          id: 9,
          label: 'آذر',
          start: '09-01',
          end: '09-30',
        },
        {
          id: 10,
          label: 'دی',
          start: '10-01',
          end: '10-30',
        },
        {
          id: 11,
          label: 'بهمن',
          start: '11-01',
          end: '11-30',
        },
        {
          id: 12,
          label: 'اسفند',
          start: '12-01',
          end: '12-29',
        },

      ],
      doctors: [],
      requesting: false,
      options: {},
      searchFilters: [],
      rerender: false,
      headers: [
        {
          text: 'نام و نام خانوادگی',
          align: 'start',
          sortable: false,
          value: 'user.full_name',
        },
        {text: 'تاریخ', value: 'fixed_date'},
        {text: 'ساعت', value: 'fixed_time'},
        {text: 'مشاور', value: 'doctor.full_name'},
        {text: 'زمان جلسه', value: 'extra_duration'},
        {text: 'پرداختی به ریال', value: 'doctor_payment_rial'},
        {text: 'پرداختی به دلار', value: 'doctor_payment_dollar'},
        {text: 'ارز', value: 'currency'},
        {text: 'عنوان بسته', value: 'package_title'},
        {text: '', value: 'action'},
      ],
      // date: moment().format("YYYY-MM-DD"),
      page: 1,
      total: 1,
      sessions: []
    }
  },
  computed: {
    ...mapGetters({
      allDoctors: 'getAdminAllDoctors'
    }),


  },
  watch: {
    '$route': function () {
      this.getAdminSessions();
    }
  },
  created() {
    this.fillDoctors();
    this.fillPageState()

  },
  methods: {
    getMonthStaticAndTargets(start, end, id) {
      this.activeType = null
      this.selectedMonth = id
      let year = moment()
      year = year.format('jYYYY-')

      start = year + start
      end = year + end

      adminCounselorPaymentFilters.find((item) => {
        if (item.key == 'from_date') {

          EventBus.$emit('directOptionSet', {key: item.key, value: this.jalaliToGregorian(start, 'YYYY-MM-DD')})
        } else if (item.key == 'to_date') {
          EventBus.$emit('directOptionSet', {key: item.key, value: this.jalaliToGregorian(end, 'YYYY-MM-DD')})
        }
      });
      EventBus.$emit('submitOptions')
    },
    jalaliToGregorian(date, format = 'Y-MM-DD') {
      return moment(date, 'jYYYY-jM-jDD').format(format)
    },
    fillDoctors() {
      let promise = new Promise((resolve, reject) => {
        if (!this.allDoctors.length) {
          this.$store.dispatch("getAllDoctors").then(res => {
            this.doctors = res.data.doctors;
            resolve();
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.doctors = this.allDoctors;
          resolve();
        }
      })

      promise.then(() => {
        let mappedDoctors = this.doctors.map((doctor) => {
          return {
            title: doctor.full_name, value: doctor.doctor_profile.id
          };
        });
        adminCounselorPaymentFilters.find((item) => {
          if (item.key == 'doctor_ids') {
            item.options = mappedDoctors;
          }
        });
        this.searchFilters = adminCounselorPaymentFilters;
        this.rerender = true;
      });

    },
    applyFilters(options) {
      this.resetPaginationInApplyFilterIfNeeded(options)
      this.options = options;
      this.getAdminSessions();
    },

    getAdminSessions() {

      EventBus.$emit('setLoading', true);
      this.requesting = true;
      this.$store.dispatch('getAdminDoctorSessionsPayment', {page: this.page, options: this.options}).then((data) => {
        console.log(data)
        this.sessions = data.data.sessions;
        this.total = data.data.pagination.total_pages;
        this.requesting = false;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
      this.requesting = false;
    },

    getClientProfile(id) {
      this.$router.push({name: 'clientDetail', params: {clientId: id}});
    },
  }
}
</script>
